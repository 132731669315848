import { Link } from "react-router-dom";
import home from "../assets/home.svg";
import login from "../assets/login.svg";

const Home = () => {
  return (
    <div
      id={"Home"}
      className="bg-gradient-to-r from-[#FFF] to-[#FFF] md:max-w-full py-16"
    >
      <div className="md:flex">
        <div className="md:shrink-2 px-20">
          <img src={home} class="w-60 h-45 bg-cover" alt="image description" />
        </div>
        {/* Small register image */}
        <div class="max-w-sm rounded-3xl ml-14 overflow-hidden shadow-2xl opacity-6 border border-borderLight place-self-center py-14 px-6">
          <img src={login} className="w-60 h-96" alt="image description" />
          <div className="flex flex-row justify-center items-center">
            <div>
              <p className="text-black poppins-semibold text-xl pr-6 subpixel-antialiased">
                {"REGISTER NOW"}
              </p>
            </div>
            <div>
              <button class="bg-transparent rounded-full  p-6 bg-primaryOrange">
                <Link
                  activeClass="active"
                  to={"ContactUs"}
                  spy={true}
                  smooth={true}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
