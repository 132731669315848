import React from "react";
import Home from "../screens/Home";
import Services from "../screens/Services";
import About from "../screens/About";
import ContactUs from "../screens/ContactUs";

function MainScreen() {
  return (
    <>
      <Home />
      <Services />
      <About />
      <ContactUs />
    </>
  );
}

export default MainScreen;
