import { Input, Textarea } from "@material-tailwind/react";
import React, { useState } from "react";

const ContactUs = () => {
  const [firstName, setFirstName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [message, setMessage] = useState();
  const onChangeFirstName = (text) => {
    setFirstName(text);
  };
  const onChangeEmail = (text) => {
    setEmail(text);
  };
  const onChangeCompany = (text) => {
    setCompany(text);
  };
  const onChangePhoneNumber = (text) => {
    setPhoneNumber(text);
  };
  const onChangeMessage = (text) => {
    setMessage(text);
  };
  const gotoSendEmail = () => {
    console.log("firstName", firstName);
    console.log("email", email);
    console.log("Company", company);
    console.log("phoneNumber", phoneNumber);
    console.log("message", message);
  };
  return (
    <div id="ContactUs" className="bg-white md:py-10 mb-32">
      <div className="mx-auto max-w-9xl px-6 lg:px-6 animate-fade-up animate-once animate-ease-in-out animate-normal animate-fill-both">
        {/* Title of the provide services */}
        <div className="mx-auto lg:text-center py-8">
          <h1 className="text-center poppins-regular text-lg py-10 text-primaryBlue">
            LET US HELP YOU FIND THE PERFECT IT SOLUTION
          </h1>
          <p className="text-4xl tracking-wider text-center poppins-medium text-primaryBlue">
            What Is Right For{" "}
            <span className=" poppins-semibold text-primaryOrange">
              My Business ?
            </span>
          </p>
        </div>

        {/* <div className="bg-gradient-to-r from-[#D2E9F6] to-[#fcfefe] md:max-w-full"> */}
        <div className="bg-gradient-to-r from-[#FFF] to-[#FFF] md:max-w-full">
          {/* <div className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"/> */}
        </div>
        <form
          action="#"
          method="POST"
          className="mx-auto mt-16 max-w-2xl sm:mt-20"
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="first-name"
                className="block poppins-semibold text-sm tracking-wide"
              >
                First name
              </label>
              <div className="mt-2.5">
                <Input
                  onChange={(text) => onChangeFirstName(text)}
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="border-0 border-primaryBlue block w-full rounded-lg px-3.5 py-3 text-black ring-darkBlue focus:ring-2 focus:ring-darkBlue"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block poppins-semibold text-sm tracking-wide"
              >
                Email
              </label>
              <div className="mt-2.5">
                <Input
                  onChange={(text) => onChangeEmail(text)}
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="border-0 border-primaryBlue block w-full rounded-lg px-3.5 py-3 text-black ring-darkBlue focus:ring-2 focus:ring-darkBlue"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block poppins-semibold text-sm tracking-wide"
              >
                Company
              </label>
              <div className="mt-2.5">
                <Input
                  onChange={(text) => onChangeCompany(text)}
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="organization"
                  className="border-0 border-primaryBlue block w-full rounded-lg px-3.5 py-3 text-black ring-darkBlue focus:ring-2 focus:ring-darkBlue"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone-number"
                className="block poppins-semibold text-sm tracking-wide"
              >
                Phone number
              </label>
              <div className="mt-2.5">
                {/* <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    >
                      <option>US</option>
                      <option>CA</option>
                      <option>EU</option>
                    </select>
                    <ChevronDownIcon
                      className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div> */}
                <Input
                  onChange={(text) => onChangePhoneNumber(text)}
                  type="tel"
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel"
                  className="border-0 border-primaryBlue block w-full rounded-lg px-3.5 py-3 text-black ring-darkBlue focus:ring-2 focus:ring-darkBlue"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block poppins-semibold text-sm tracking-wide"
              >
                Please describe what you need
              </label>
              <div className="mt-2.5">
                <Textarea
                  onChange={(text) => onChangeMessage(text)}
                  name="message"
                  id="message"
                  rows={4}
                  className="border-0 border-primaryBlue block w-full rounded-lg px-3.5 py-3 text-black ring-darkBlue focus:ring-2 focus:ring-darkBlue"
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20">
          <button
            type="submit"
            className="block w-full rounded-lg outline hover:outline-darkBlue outline-white outline-1 outline-offset-2 bg-darkBlue text-white px-3.5 py-3.5"
            onClick={() => gotoSendEmail()}
          >
            <p className="poppins-semibold text-sm tracking-wide">
              {"Let's talk"}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
