import React from "react";
import { FaLinkedinIn, FaInstagram } from "react-icons/fa6";
import { Link } from "react-scroll";
import SmallLogo from "../assets/smallLogo.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const gotoHome = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  };
  return (
    <div className="bg-white px-20 pt-20 shadow-2xl border-t-2	border-footerBorder">
      <div className="flex flex-row justify-between	">
        <div>
          <button type="button" onClick={() => gotoHome()}>
            {/* <Link activeClass="active" to={"Home"} spy={true} smooth={true}> */}
            <img className="h-32 w-36 bg-cover pl-6" src={SmallLogo} />
            {/* </Link> */}
          </button>
          <p className="mt-2 text-sm leading-8 pl-4 text-gray poppins-regular">
            We are always looking for great people to join the EnFix Solutions
            team!
          </p>
          <div className="flex flex-row items-center py-4">
            <button
              onClick={() =>
                window.open(
                  "https://www.instagram.com/enfix.solutions?igsh=MTl6aXAzZ2Rpb3lxeg=="
                )
              }
            >
              <div className="p-4">
                <FaInstagram size={22} color="#0B60B0" />
              </div>
            </button>
            <button
              onClick={() =>
                window.open("https://www.linkedin.com/company/enfix-solutions/")
              }
            >
              <div className="p-4">
                <FaLinkedinIn size={22} color="#0B60B0" />
              </div>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-32 gap-y-10 2xl:grid-cols-2 2xl:pt-2 ">
          <div className="flex flex-col ">
            <p class="text-black poppins-medium text-sm	 my-4">{"Contact"}</p>
            <p class="text-gray poppins-regular text-sm my-2">
              {"(224) 400-0321"}
            </p>
            <p class="text-gray poppins-regular text-sm my-2">
              {"Info@enfix.solutions"}
            </p>
          </div>
          <div className="flex flex-col ">
            <p class="text-black poppins-medium text-sm	 my-4">{"Company"}</p>
            <p class="text-gray poppins-regular text-sm my-2">
              {"10:00AM - 04:00PM"}
            </p>
            <p class="text-gray poppins-regular text-sm my-2">
              {"Monday - Friday"}
            </p>
            <p class="text-gray poppins-regular text-sm my-2">
              {/* {"India, Canada, USA"} */}
              {"USA"}
            </p>
          </div>
        </div>
      </div>
      <div class="py-6 bg-white border-t border-borderLight">
        <p class="text-gray poppins-regular text-xs ml-2">
          {"© 2024 EnFix it Solutions, All rights reserved."}
        </p>
      </div>
    </div>
  );
};
export default Footer;
