import React, { useState } from "react";
const About = () => {
  const [isWhy, setIsWhy] = useState(true);
  const [isWhat, setIsWhat] = useState(false);
  const [isHow, setIsHow] = useState(false);

  const stats = [
    { id: 1, name: "Users on the platform", value: "99+" },
    { id: 2, name: "Project Served", value: "350+" },
    { id: 3, name: "company", value: "21+" },
  ];

  const updateWhy = () => {
    setIsWhat(false);
    setIsWhy(true);
    setIsHow(false);
  };
  const updateWhat = () => {
    setIsWhat(true);
    setIsWhy(false);
    setIsHow(false);
  };
  const updateHow = () => {
    setIsWhat(false);
    setIsWhy(false);
    setIsHow(true);
  };
  return (
    <>
      <div id="About" className="bg-white md:py-1">
        <div className="mx-auto max-w-6xl px-6 lg:px-6 animate-fade-up animate-once animate-ease-in-out animate-normal animate-fill-both">
          {/* Title of the provide services */}
          <div className="mx-auto lg:text-center py-1">
            <h1 className="text-center poppins-regular text-primaryBlue text-lg py-10">
              FACE IT, YOU NEED ENFiX Solutions.
            </h1>
            <p className="text-4xl tracking-wider text-center poppins-medium text-primaryBlue">
              The right IT service is the one you{" "}
              <span className=" text poppins-semibold text-primaryOrange">
                don’t worry about.
              </span>
            </p>
          </div>
          <div className="mx-auto max-w-4xl px-6m md:py-10 flex justify-between ">
            <button
              type="submit"
              className="outline hover:outline-darkBlue outline-white outline-1 outline-offset-2 flex-1 items-center justify-center rounded-lg border border-transparent bg-white text-darkBlue hover:bg-darkBlue hover:text-white  py-4  px-10 mx-4"
              onClick={() => updateWhy()}
              style={{
                outline: isWhy ? "#0B60B0" : "white",
                background: isWhy ? "#0B60B0" : "white",
                color: isWhy ? "white" : "#0B60B0",
              }}
            >
              <p className="poppins-semibold text-sm tracking-wide">{"WHY"}</p>
            </button>
            <button
              type="submit"
              className="outline hover:outline-darkBlue outline-white outline-1 outline-offset-2 flex-1 items-center justify-center rounded-lg border border-transparent bg-white text-darkBlue hover:bg-darkBlue hover:text-white  py-4  px-10 mx-4"
              onClick={() => updateWhat()}
              style={{
                outline: isWhat ? "#0B60B0" : "white",
                background: isWhat ? "#0B60B0" : "white",
                color: isWhat ? "white" : "#0B60B0",
              }}
            >
              <p className="poppins-semibold text-sm tracking-wide">{"WHAT"}</p>
            </button>
            <button
              type="submit"
              className="outline hover:outline-darkBlue outline-white outline-1 outline-offset-2 flex-1 items-center justify-center rounded-lg border border-transparent bg-white text-darkBlue hover:bg-darkBlue hover:text-white  py-4  px-10 mx-4"
              onClick={() => updateHow()}
              style={{
                outline: isHow ? "#0B60B0" : "white",
                background: isHow ? "#0B60B0" : "white",
                color: isHow ? "white" : "#0B60B0",
              }}
            >
              <p className="poppins-semibold text-sm tracking-wide">{"How"}</p>
            </button>
          </div>

          {isWhy && (
            <div className="pt-10 animate-fade-up animate-once animate-ease-linear animate-normal animate-fill-forwards">
              <p className=" mb-3 poppins-semibold tracking-wider  dark:text-gray-400 first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-primaryBlue dark:first-letter:text-gray-100 first-letter:me-3 first-letter:float-start">
                At EnFix Solutions, we stand out as your trusted partner in
                navigating the complexities of the digital landscape. With our
                unwavering commitment to excellence, innovative approach, and
                customer-centric ethos, we offer compelling reasons to choose us
                as your preferred technology partner.
              </p>
              <p className="dark:text-gray-400 pb-32 poppins-regular tracking-wider">
                Choose EnFix Solutions for expertise, innovation, and a
                collaborative partnership that drives results. Contact us today
                to learn more about how we can help you take your business to
                the next level. First and foremost, our expertise sets us apart.
                With a team of seasoned professionals boasting extensive
                experience in diverse domains of technology, we possess the
                knowledge and skills to tackle even the most challenging
                projects. Whether you require AI-powered solutions, robust
                software development, or comprehensive IT consulting, we have
                the expertise to deliver results that exceed your expectations.
                Additionally, our dedication to customer satisfaction is
                unparalleled. We prioritize clear communication, transparent
                processes, and collaborative partnerships to ensure that your
                needs are not just met but surpassed. When you choose EnFix
                Solutions, you're choosing a reliable ally committed to your
                success every step of the way.
              </p>
            </div>
          )}
          {isWhat && (
            <div className="pt-10 animate-fade-up animate-once animate-ease-linear animate-normal animate-fill-forwards">
              <p className=" mb-3 poppins-semibold tracking-wider  dark:text-gray-400 first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-primaryBlue dark:first-letter:text-gray-100 first-letter:me-3 first-letter:float-start">
                At EnFix Solutions is your trusted partner for navigating the
                complexities of the digital landscape. With a focus on
                innovation, efficiency, and customer satisfaction, we offer a
                comprehensive suite of services tailored to meet your business
                needs.
              </p>
              <p className="dark:text-gray-400 pb-32 poppins-regular tracking-wider">
                we understand that every business is unique, which is why we
                take a personalized approach to every project. Our team works
                closely with you to understand your specific requirements and
                objectives, ensuring that our solutions are perfectly aligned
                with your business goals. With a focus on quality, reliability,
                and innovation, we strive to exceed your expectations and
                deliver exceptional results that propel your business forward.
                Partner with EnFix Solutions today and unlock the full potential
                of technology to drive your success From AI-powered solutions to
                software development, data analytics, and beyond, our team of
                experts is dedicated to delivering cutting-edge technology
                solutions that drive growth and success. Whether you're looking
                to streamline processes, enhance productivity, or gain valuable
                insights from your data, EnFix Solutions has the expertise and
                experience to help you achieve your goals.
              </p>
            </div>
          )}
          {isHow && (
            <div className="pt-10 animate-fade-up animate-once animate-ease-linear animate-normal animate-fill-forwards">
              <p className=" mb-3 poppins-semibold tracking-wider  dark:text-gray-400 first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-primaryBlue dark:first-letter:text-gray-100 first-letter:me-3 first-letter:float-start">
                At EnFix Solutions is your trusted partner in building robust
                and scalable software solutions tailored to your business needs.
                Our comprehensive approach to software development begins with a
                deep understanding of your requirements and objectives.
              </p>
              <p className="dark:text-gray-400 pb-32 poppins-regular tracking-wider">
                we gather insights to design a roadmap that aligns with your
                vision and goals. Leveraging the latest technologies and best
                practices, our expert team meticulously crafts custom software
                solutions that empower your business to thrive in the digital
                age. From conceptualization to deployment and beyond, EnFix
                Solutions is committed to delivering excellence at every stage
                of the development process. Our agile methodology ensures
                flexibility and adaptability, allowing us to respond swiftly to
                evolving requirements and market dynamics. With a focus on
                transparency and communication, At EnFix Solutions, we
                understand that building successful solutions is not just about
                writing code—it's about solving problems and creating value for
                our clients. That's why we take a holistic approach to software
                development, focusing not only on technical excellence but also
                on delivering tangible business outcomes. Whether you need a
                simple website, a complex enterprise application, or anything in
                between, you can trust EnFix Solutions to build a solution that
                drives results and helps you achieve your business objectives.
              </p>
            </div>
          )}

          <div className="bg-white ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                {stats.map((stat) => (
                  <div
                    key={stat.id}
                    className="mx-auto flex max-w-xs flex-col gap-y-1"
                  >
                    <dt className="text-xl leading-7 poppins-medium text-primaryBlue">
                      {stat.name}
                    </dt>
                    <dd className="order-first mt-4 text-3xl tracking-tight sm:text-5xl poppins-semibold text-primaryOrange">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
