import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
function ServiceDetails() {
  const location = useLocation();
  const serviceName = location?.state.name;
  const serviceID = location?.state.id;
  console.log("i am in location=>", serviceName);

  const [goingUp, setGoingUp] = useState(false);
  const [visible, setVisible] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 0) {
        setVisible(false);
      } else {
        setVisible(true);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  }, []);

  return (
    <>
      <div style={{ paddingTop: visible ? 80 : 0 }}>
        {serviceID === 1 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions, we understand the pivotal role that
                        a well-crafted website plays in today's digital
                        landscape. With our expertise in website development, we
                        empower businesses to establish a compelling online
                        presence that captivates audiences and drives success.
                        Our dedicated team of developers meticulously crafts
                        every aspect of your website, from sleek design elements
                        to seamless functionality, ensuring a user experience
                        that exceeds expectations. Whether you're a startup
                        looking to make your mark or an established enterprise
                        seeking to enhance your online footprint, EnFix
                        Solutions is your trusted partner for unlocking the full
                        potential of your web presence.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Faster Load Speeds
                            </strong>{" "}
                            Optimized code and streamlined design result in
                            faster load times, enhancing user experience and
                            reducing bounce rates.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Accelerated Crawling and Indexing
                            </strong>{" "}
                            Search engine bots can easily crawl and index your
                            website's content, ensuring that it's promptly
                            included in search engine results.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Higher Search Engine Rankings
                            </strong>{" "}
                            By adhering to best practices in web development and
                            SEO, we help boost your website's visibility and
                            rankings in search engine results pages (SERPs).
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        Whether you're a small startup or a large corporation,
                        our team is committed to delivering custom web
                        development solutions tailored to your specific business
                        objectives. By leveraging the latest technologies and
                        industry best practices, we empower your business to
                        thrive in the competitive online landscape.
                      </p>
                      <p className="mt-6">
                        Partner with EnFix Solutions today and unlock the full
                        potential of your online presence. Let's collaborate to
                        create a responsive, SEO-friendly website that drives
                        traffic, engages users, and propels your business
                        towards success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 2 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions, offers a cutting-edge software
                        development service tailored to meet the unique needs of
                        businesses across various industries. Our team of
                        experienced developers specializes in crafting robust,
                        scalable, and innovative solutions that drive digital
                        transformation and business growth. By leveraging the
                        latest technologies and best practices, we ensure that
                        our clients stay ahead of the competition in today's
                        fast-paced digital landscape.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Faster Load Speeds
                            </strong>{" "}
                            Optimized code and streamlined design result in
                            faster load times, enhancing user experience and
                            reducing bounce rates.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Robust Software Development
                            </strong>{" "}
                            Being the best software development company in the
                            US, we maintain a high standard and offer turnkey
                            software development services with all the required
                            nuances.
                          </span>
                        </li>
                        {/* <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Higher Search Engine Rankings
                            </strong>{" "}
                            By adhering to best practices in web development and
                            SEO, we help boost your website's visibility and
                            rankings in search engine results pages (SERPs).
                          </span>
                        </li> */}
                      </ul>
                      <p className="mt-8">
                        Our approach to software development is collaborative
                        and client-centric, ensuring that we fully understand
                        your requirements and objectives. Whether you need a
                        custom web application, mobile app, or enterprise
                        solution, we have the expertise to bring your vision to
                        life. From initial concept to final delivery, we are
                        committed to exceeding your expectations and delivering
                        solutions that empower your business to thrive in
                        today's competitive market.
                      </p>
                      <p className="mt-6">
                        Partner with EnFix Solutions for your next software
                        development project and experience the difference that
                        expertise and dedication can make. Contact us today to
                        learn more about our services and how we can help you
                        achieve your business goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 3 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions recognizes the critical importance of
                        network and security measures in safeguarding our
                        website and the data of our clients. We have implemented
                        a robust framework to ensure the integrity,
                        confidentiality, and availability of our services.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Firewalls
                            </strong>{" "}
                            Deploying firewalls at network perimeters and within
                            internal segments to monitor and control incoming
                            and outgoing traffic based on predefined security
                            rules. Firewalls help prevent unauthorized access
                            and protect against common network attacks.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Intrusion Detection and Prevention Systems (IDPS)
                            </strong>{" "}
                            Using IDPS solutions to monitor network traffic for
                            signs of suspicious or malicious activity. These
                            systems can detect and respond to security incidents
                            in real-time, helping to mitigate potential threats.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Patch Management
                            </strong>{" "}
                            Keeping network devices and software up to date with
                            the latest security patches and updates to address
                            known vulnerabilities. Regular patching helps
                            mitigate the risk of exploitation by cyber
                            attackers.
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        At the network level, EnFix Solutions implements a
                        secure and resilient infrastructure. This includes
                        employing firewalls, intrusion detection and prevention
                        systems (IDPS), and regular security audits to detect
                        and mitigate potential vulnerabilities. We utilize a
                        combination of physical and virtual firewalls to control
                        and monitor traffic flow, ensuring that only authorized
                        users and data packets are allowed access to our
                        network.
                      </p>
                      <p className="mt-6">
                        By prioritizing network resilience and implementing
                        robust security measures, EnFix Solutions ensures the
                        availability, integrity, and confidentiality of its
                        website and the data it handles. We are committed to
                        maintaining a secure online environment for our users
                        and stakeholders.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 4 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions, we prioritize the confidentiality,
                        integrity, and availability of data through stringent
                        cybersecurity measures. From employing advanced
                        firewalls and intrusion detection systems to
                        implementing strict password policies and regular
                        employee training, we create a secure environment for
                        our clients' digital assets. Additionally, we adhere to
                        industry best practices and compliance standards to
                        guarantee the highest level of security for our
                        services. With EnFix Solutions, clients can rest assured
                        that their data is protected against evolving cyber
                        threats, allowing them to focus on their core business
                        activities without worrying about security breaches.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Implement Strong Access Controls{" "}
                            </strong>{" "}
                            Optimized code and streamlined design result in
                            faster load times, enhancing user experience and
                            reducing bounce rates.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Data Encryption
                            </strong>{" "}
                            Encrypt sensitive data both in transit and at rest
                            to protect it from unauthorized access. Use
                            encryption protocols such as HTTPS, SSL/TLS, and AES
                            for secure communication and storage.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Regular Software Updates and Patch Management{" "}
                            </strong>{" "}
                            Keep all software, including operating systems,
                            applications, and security tools, up to date with
                            the latest patches and updates to address known
                            vulnerabilities.
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        We employ a holistic approach to cybersecurity,
                        encompassing robust encryption protocols, stringent
                        access controls, and continuous monitoring of network
                        activity. Our team of experienced professionals stays
                        vigilant, conducting regular security audits and
                        implementing industry best practices to fortify our
                        defenses.
                      </p>
                      <p className="mt-6">
                        Partner with EnFix Solutions for robust cybersecurity
                        solutions tailored to your specific needs. Trust us to
                        protect your digital assets and mitigate risks
                        effectively, allowing you to focus on achieving your
                        business objectives with confidence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 5 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions, we understand the critical role that
                        data plays in driving business success. Our robust data
                        management framework ensures the integrity, security,
                        and accessibility of data across the entire data
                        lifecycle. Leveraging the latest technologies and best
                        practices, we help organizations streamline their data
                        processes, minimize risks, and maximize the value of
                        their data assets.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Data Governance and Compliance{" "}
                            </strong>{" "}
                            Data management involves establishing policies,
                            procedures, and controls to ensure data governance
                            and compliance with regulatory requirements. This
                            includes defining data ownership, access controls,
                            and privacy measures to protect sensitive
                            information.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Analytics and Insights Generation
                            </strong>{" "}
                            Data analytics involves using statistical techniques
                            and algorithms to analyze data and extract
                            meaningful insights. This includes descriptive
                            analytics to summarize data, predictive analytics to
                            forecast future trends, and prescriptive analytics
                            to recommend actions.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Continuous Improvement and Optimization{" "}
                            </strong>{" "}
                            Data management is an ongoing process that requires
                            continuous improvement and optimization. This
                            involves monitoring data quality, performance, and
                            usage patterns to identify areas for improvement and
                            optimize data management processes and systems.
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        Our analytics capabilities enable organizations to
                        uncover valuable insights hidden within their data,
                        enabling data-driven decision-making and strategic
                        planning. Whether it's predictive analytics, machine
                        learning, or business intelligence, EnFix Solutions
                        empowers clients to extract actionable insights that
                        drive tangible business outcomes.
                      </p>
                      <p className="mt-6">
                        Partner with EnFix Solutions today and unlock the full
                        potential of your data. Experience unparalleled
                        expertise, innovative solutions, and personalized
                        support that propels your business towards success in
                        the digital age. Discover how EnFix Solutions can
                        transform your data into a strategic asset that fuels
                        growth, innovation, and competitive advantage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 6 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions, we strive to provide seamless
                        technical support to ensure our customers have a smooth
                        experience with our products and services. Whether
                        you're encountering an issue with our website, software,
                        or services, our dedicated support team is here to
                        assist you every step of the way.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Website Loading Slowly{" "}
                            </strong>{" "}
                            If you're experiencing slow loading times on our
                            website, try clearing your browser cache and cookies
                            or accessing the website from a different browser.
                            If the issue persists, please reach out to our
                            support team for further assistance.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Login Problems
                            </strong>{" "}
                            If you're having trouble logging into your account,
                            ensure that you're entering the correct username and
                            password. If you've forgotten your password, you can
                            reset it using the "Forgot Password" option on the
                            login page.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Compatibility Issues
                            </strong>{" "}
                            Our products and services are designed to work
                            seamlessly across various devices and platforms.
                            However, if you encounter compatibility issues, make
                            sure your device meets the minimum system
                            requirements outlined on our website.
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        We value your feedback and suggestions for improving our
                        products and services. If you have any ideas or
                        suggestions for how we can enhance your experience,
                        please don't hesitate to share them with us. Your input
                        helps us continue to provide high-quality solutions
                        tailored to your needs.
                      </p>
                      <p className="mt-6">
                        Thank you for choosing EnFix Solutions. We're here to
                        help you succeed!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 7 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions, is proud to introduce its innovative
                        IoT (Internet of Things) solution designed to
                        revolutionize the way businesses operate. Our IoT
                        solution is tailored to enhance efficiency,
                        productivity, and sustainability across various
                        industries.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Automation and Optimization:
                            </strong>{" "}
                            By leveraging IoT technology, businesses can
                            automate routine tasks, optimize resource
                            utilization, and streamline workflows. From
                            predictive maintenance to energy management, our IoT
                            solution helps businesses operate more efficiently
                            and effectively.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Integration and Compatibility{" "}
                            </strong>{" "}
                            EnFix Solutions' IoT solution is designed to
                            seamlessly integrate with existing systems and
                            technologies, ensuring compatibility and
                            interoperability across different platforms and
                            devices. This enables businesses to leverage their
                            existing infrastructure while unlocking the full
                            potential of IoT technology.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Real-time Monitoring and Analytics
                            </strong>{" "}
                            Our IoT solution enables businesses to monitor
                            equipment performance, track inventory, and analyze
                            operational data in real-time. Through advanced
                            analytics, businesses can gain valuable insights
                            into their processes, identify trends, and make
                            data-driven decisions to improve productivity and
                            reduce costs.
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        Security is paramount in the digital age, and our IoT
                        solution prioritizes data protection and privacy. We
                        employ robust encryption protocols and authentication
                        mechanisms to safeguard sensitive information, ensuring
                        peace of mind for our clients.
                      </p>
                      <p className="mt-6">
                        Partner with EnFix Solutions committed to delivering
                        value-driven IoT solutions that empower businesses to
                        thrive in today's dynamic marketplace. Contact us today
                        to learn more about how our IoT solution can transform
                        your business operations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 8 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions leverages Amazon Web Services (AWS)
                        to power its website and deliver a seamless experience
                        to its users. By utilizing AWS's robust infrastructure,
                        EnFix ensures high availability, scalability, and
                        security for its online platform.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Cost-Efficiency
                            </strong>{" "}
                            With AWS, EnFix Solutions can optimize costs by only
                            paying for the resources they use. AWS offers
                            flexible pricing models, including pay-as-you-go and
                            reserved instances, allowing EnFix Solutions to
                            scale resources up or down based on demand while
                            minimizing expenses.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Global Reach{" "}
                            </strong>{" "}
                            AWS operates data centers in multiple regions
                            worldwide, enabling EnFix Solutions to deliver
                            content to users with low latency and high
                            availability across the globe. This ensures fast and
                            responsive access to the website for users
                            regardless of their location.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Scalable Storage and Content Delivery
                            </strong>{" "}
                            AWS offers a range of storage solutions, including
                            Amazon S3 for scalable object storage and Amazon
                            CloudFront for content delivery with low latency and
                            high transfer speeds. EnFix Solutions can
                            efficiently store and deliver website content, media
                            files, and other digital assets to users worldwide.
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        AWS's managed database services, such as Amazon RDS
                        (Relational Database Service) and Amazon DynamoDB, power
                        EnFix's data storage and management requirements. These
                        services offer high availability, durability, and
                        automated backups, enabling EnFix to store and retrieve
                        data efficiently while maintaining data integrity.
                      </p>
                      <p className="mt-6">
                        Additionally, EnFix Solutions utilizes AWS Lambda to run
                        serverless functions, enabling seamless integration with
                        other AWS services and third-party APIs. This serverless
                        architecture reduces operational overhead and enables
                        EnFix to focus on developing innovative features for its
                        website without managing infrastructure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : serviceID === 9 ? (
          <div>
            <div
              class="bg-cover pt-0"
              style={{
                backgroundImage: "url(" + require("../assets/ai.jpeg") + ")",
                // height: visible ? 400 : 600,
                height: 350,
              }}
            >
              <div
                class="bg-cover bg-black opacity-50"
                style={{
                  height: 350,
                }}
              >
                <div className="flex items-center justify-center pt-40">
                  <p className="text-6xl text-white poppins-semibold tracking-wider text-center align-middle">
                    {serviceName}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-20">
              <h1 className="text-2xl text-primaryOrange poppins-semibold tracking-wider text-center p-4">
                {"Business " + serviceName + " Services"}
              </h1>

              <div className="relative isolate overflow-hidden bg-white px-6 py-0 lg:overflow-visible lg:px-0 p-10">
                <div className="mx-auto grid max-w-6xl grid-cols-1 gap-x-8  gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
                  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                      <div>
                        <p className="text-base leading-7">{"Development"}</p>
                        <h1 className="mt-4 mb-4 text-3xl tracking-tight sm:text-4xl">
                          {"A better workflow"}
                        </h1>
                      </div>
                    </div>
                    <div className="tmt-6 text-l leading-8 text-gray-700">
                      <p>
                        At EnFix Solutions, we embrace the transformative power
                        of Artificial Intelligence (AI) to revolutionize
                        businesses across industries. AI isn't just a buzzword;
                        it's the cornerstone of modern innovation, enabling
                        companies to streamline operations, enhance
                        decision-making, and unlock unprecedented opportunities
                        for growth.
                      </p>
                      <ul role="list" className="mt-8 space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CloudArrowUpIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Empowering Solutions with AI
                            </strong>{" "}
                            we harness the power of Artificial Intelligence (AI)
                            to drive transformative changes in businesses across
                            diverse industries.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <LockClosedIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Tailored AI Solutions
                            </strong>{" "}
                            Our team of experts crafts customized AI solutions
                            to address your unique business challenges, whether
                            it's automating processes, optimizing workflows, or
                            enhancing decision-making.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <ServerIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            <strong className="font-semibold text-gray-900">
                              Ethical AI Practices
                            </strong>{" "}
                            Ethical considerations are paramount in our AI
                            development process. We adhere to strict ethical
                            standards to ensure fairness, transparency, and
                            accountability in all our AI solutions.
                          </span>
                        </li>
                      </ul>
                      <p className="mt-8">
                        Whether you're looking to deploy AI solutions across
                        your entire organization or scale them gradually, EnFix
                        Solutions has you covered. Our scalable and flexible AI
                        infrastructure can grow alongside your business,
                        ensuring that you always have access to the resources
                        you need to succeed in an increasingly digital world.
                      </p>
                      <p className="mt-6">
                        Partner with EnFix Solutions We believe in the power of
                        data-driven insights. Our AI algorithms analyze vast
                        amounts of data to uncover valuable patterns, trends,
                        and correlations. By leveraging these insights, you can
                        make informed business decisions, identify new
                        opportunities, and stay ahead of the competition in
                        today's rapidly evolving marketplace.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default ServiceDetails;
