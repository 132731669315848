import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./screens/About";
import Services from "./screens/Services";
import ServiceDetails from "./screens/ServiceDetails";
import ContactUs from "./screens/ContactUs";
import Home from "./screens/Home";
import MainScreen from "./screens/MainScreen";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/ServiceDetails" element={<ServiceDetails />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
