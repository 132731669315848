import { FaLinkedinIn, FaInstagram } from "react-icons/fa6";
import { useEffect, useRef } from "react";
import { Popover, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-scroll";
import { useState } from "react";
import Logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();

  const navigation = [
    { name: "Home", href: "Home", current: true },
    { name: "Solutions", href: "Services", current: false },
    { name: "About", href: "About", current: false },
    { name: "Contact Us", href: "ContactUs", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const gotoHome = () => {};
  const [visible, setVisible] = useState(true);
  const prevScrollY = useRef(0);

  const [goingUp, setGoingUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 0) {
        setVisible(false);
      } else {
        setVisible(true);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);
  const gotoMobileView = () => {
    setMobileNavOpen(false);
    navigate("/");
  };
  return (
    <>
      <div className="bg-white pt-4 w-full shadow-xl pb-2">
        {/* Top Line of the Website */}
        {mobileMenuOpen || mobileNavOpen ? (
          <div class="pb-4 border-b border-borderLight">
            <div class="flex flex-row justify-center">
              <a
                href="#"
                class="text-darkBlue text-center poppins-semibold text-xs "
              >
                Career Opportunities:
              </a>
              <p class="text-gray text-center poppins-regular text-xs ml-2">
                We are always looking for great people to join the Facet team!
              </p>
            </div>
          </div>
        ) : null}
        <header>
          <nav className="flex justify-between p-6 items-center mx-auto max-w-7xl">
            <button type="button" className="" onClick={() => gotoHome()}>
              <div className="lg:flex-2 hidden lg:block">
                <img
                  className="h-24 w-44 bg-cover md:w-10 lg:w-48 block"
                  src={Logo}
                />
              </div>
            </button>

            <div className="lg:hidden fixed w-full bg-white z-10 top-0 animate-fade-down">
              <div className="flex justify-between flex-row px-12 pt-10 pb-4">
                <img
                  className="h-24 w-44 bg-cover md:w-10 lg:w-48 block"
                  src={Logo}
                />
                <div>
                  <button
                    type="button"
                    className="-m-2.5 inline-flex justify-center rounded-md text-gray-700 p-6"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-6 text-gray-700"
                    onClick={() => setMobileNavOpen(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <Popover.Group className="hidden lg:flex flex-row px-10">
              {/* 1st navigation card */}
              <div className="flex flex-row border-l  border-borderLight p-2 mr-10">
                <div className="ml-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#f58220"
                    className="w-8 h-8"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="flex flex-col items-start ml-1">
                  <p class="text-black text-center poppins-semibold text-sm ml-2">
                    {"(224) 400-0321"}
                  </p>
                  <p class="text-gray text-center poppins-regular text-xs ml-2 mt-1">
                    {"Info@enfix.solutions"}
                  </p>
                </div>
              </div>
              {/* 2st navigation card */}
              <div className="flex flex-row border-l  border-borderLight p-2 mr-10">
                <div className="ml-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#f58220"
                    className="w-8 h-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div className="flex flex-col items-start ml-1">
                  <p class="text-black justify-center mt-2 text-center poppins-semibold text-sm ml-2">
                    {"Chicago, USA"}
                  </p>
                  {/* <p class="text-gray text-center poppins-regular text-xs ml-2 mt-1">
                    {"India, Canada, USA"}
                  </p> */}
                  {/* <p class="text-black text-center poppins-semibold text-sm ml-2">
                    {"Chicago, USA"}
                  </p> */}
                </div>
              </div>
              {/* 3st navigation card */}
              <div className="flex flex-row border-l  border-borderLight p-2 mr-10">
                <div className="ml-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#f58220"
                    className="w-8 h-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div className="flex flex-col items-start ml-1">
                  <p class="text-black text-center poppins-semibold text-sm ml-2">
                    {"10:00AM - 04:00PM"}
                  </p>
                  <p class="text-gray text-center poppins-regular text-xs ml-2 mt-1">
                    {"Monday - Friday"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <button
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/enfix.solutions?igsh=MTl6aXAzZ2Rpb3lxeg=="
                    )
                  }
                >
                  <div className="p-4">
                    <FaInstagram size={22} color="#0B60B0" />
                  </div>
                </button>

                <button
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/enfix-solutions/"
                    )
                  }
                >
                  <div className="p-4">
                    <FaLinkedinIn size={22} color="#0B60B0" />
                  </div>
                </button>
              </div>
            </Popover.Group>
          </nav>

          <nav
            className={`flex justify-between items-center mx-auto  w-full  z-50 sm:px-4 fixed bg-white shadow-xl ${
              !visible ? "top-0" : ""
            } `}
          >
            <div className="flex flex-1 items-center justify-center max-w-full">
              <Popover.Group className="hidden xl:flex flex-row justify-between px-10 items-center">
                {/* <img
                  className="h-10 w-24 bg-cover md:w-10 block"
                  src={SmallLogo}
                /> */}
                <div className="hiddensm:block">
                  <div className="flex space-x-4 p-6">
                    {navigation.map((item) => (
                      <div className="hover:border-b-2 px-8 border-b-2 border-white hover:border-primaryOrange flex justify-center">
                        <button
                          className={classNames(
                            (item.current
                              ? "bg-gray-900 text-primaryBlue poppins-semibold"
                              : "poppins-regular",
                            "text-darkBlue poppins-medium hover:poppins-semibold hover:darkBlue rounded-md px-3 py-2 text-sm tracking-wide")
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <Link
                            activeClass="active"
                            to={item.href}
                            spy={true}
                            smooth={true}
                            onClick={() => navigate("/")}
                          >
                            {item.name}
                          </Link>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Group>
            </div>
          </nav>

          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img className="h-24 w-44 bg-cover" src={Logo} />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {/* For mobile view */}
              {/* justify-center items-center flex flex-col */}
              <div className="pt-10 ">
                {/* 1st navigation card */}
                <div className="flex p-2 mr-16">
                  <div className="ml-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#f58220"
                      className="w-8 h-8"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col items-start ml-1">
                    <p class="text-black text-center poppins-semibold text-sm ml-2">
                      {"(224) 400-0321"}
                    </p>
                    <p class="text-gray text-center poppins-regular text-xs ml-2 mt-1">
                      {"Info@enfix.solutions"}
                    </p>
                  </div>
                </div>
                {/* 2st navigation card */}
                <div className="flex p-2 mr-16">
                  <div className="ml-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#f58220"
                      className="w-8 h-8"
                    >
                      <path
                        fill-rule="evenodd"
                        d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col items-start ml-1">
                    <p class="text-black text-center poppins-semibold text-sm ml-2">
                      {"Chicago, USA"}
                    </p>
                    <p class="text-gray text-center poppins-regular text-xs ml-2 mt-1">
                      {"India, Canada, USA"}
                    </p>
                  </div>
                </div>
                {/* 3st navigation card */}
                <div className="flex p-2 mr-16">
                  <div className="ml-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#f58220"
                      className="w-8 h-8"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col items-start ml-1">
                    <p class="text-black text-center poppins-semibold text-sm ml-2">
                      {"10:00AM - 04:00PM"}
                    </p>
                    <p class="text-gray text-center poppins-regular text-xs ml-2 mt-1">
                      {"Monday - Friday"}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row items-center pl-10 pt-4">
                  <button
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/enfix.solutions?igsh=MTl6aXAzZ2Rpb3lxeg=="
                      )
                    }
                  >
                    <div className="p-4">
                      <FaInstagram size={22} color="#0B60B0" />
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/enfix-solutions/"
                      )
                    }
                  >
                    <div className="p-4">
                      <FaLinkedinIn size={22} color="#0B60B0" />
                    </div>
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>

          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileNavOpen}
            onClose={setMobileNavOpen}
          >
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img className="h-24 w-44 bg-cover" src={Logo} />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileNavOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {/* For mobile view */}
              <div className="mt-6 flow-root">
                <div className="hiddensm:block">
                  {navigation.map((item) => (
                    <div>
                      {/* <button
                        onClick={() => handleClickScroll(item.href)}
                        key={item.name}
                        // href={item.href}
                        className={classNames(
                          (item.current
                            ? "bg-gray-900 text-primaryBlue poppins-semibold"
                            : "poppins-regular",
                          "text-darkBlue poppins-medium hover:poppins-semibold hover:darkBlue py-2 text-sm tracking-wide hover:border-b-2 px-4 border-b-2 border-white hover:border-primaryOrange")
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </button> */}
                      <button
                        className={classNames(
                          (item.current
                            ? "bg-gray-900 text-primaryBlue poppins-semibold"
                            : "poppins-regular",
                          "text-darkBlue poppins-medium hover:poppins-semibold hover:darkBlue py-2 text-sm tracking-wide hover:border-b-2 px-4 border-b-2 border-white hover:border-primaryOrange")
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <Link
                          activeClass="active"
                          to={item.href}
                          spy={true}
                          smooth={true}
                          onClick={() => gotoMobileView()}
                        >
                          {item.name}
                        </Link>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
    </>
  );
};
export default Header;
