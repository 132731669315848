import { FaAws, FaGlobe, FaDatabase, FaRobot } from "react-icons/fa6";
import {
  MdOutlineSecurity,
  MdCode,
  MdOutlineLaptopMac,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { SiCyberdefenders } from "react-icons/si";
import { useNavigate } from "react-router-dom";

const Services = (props) => {
  const navigate = useNavigate();

  const features = [
    {
      id: 1,
      name: "Website Development",
      description:
        "we specialize in crafting bespoke website development solutions that are tailored to meet the unique needs and objectives of your business. Our team of experienced developers combines cutting-edge technology with creative design to deliver high-performance websites that engage your audience and drive results.",
      icon: MdCode,
    },
    {
      id: 2,
      name: "Software Development",
      description:
        "we excel in software development, crafting bespoke solutions that cater to the unique needs of our clients. With a team of experienced developers proficient in a wide array of programming languages and frameworks, we deliver high-quality software products that drive innovation and propel businesses forward. ",
      icon: MdOutlineLaptopMac,
    },
    {
      id: 3,
      name: "Network and Security",
      description:
        "We specialize in providing comprehensive IT solutions tailored to meet your networking needs, prioritizing robust security features to protect your data and systems from cyber threats. With a dedicated team of experts and cutting-edge technologies, we ensure seamless integration, enhanced efficiency, and maximum security for your business operations.",
      icon: MdOutlineSecurity,
    },
    {
      id: 4,
      name: "Cybersecurity",
      description:
        "we are dedicated to providing comprehensive IT solutions tailored to meet your cybersecurity needs. With our team of experienced professionals, we offer a wide range of services aimed at safeguarding your digital assets and ensuring the integrity of your operations.",
      icon: SiCyberdefenders,
    },
    {
      id: 5,
      name: "Data Management and Analytics",
      description:
        "We specialize in providing comprehensive IT solutions tailored to meet your organization's unique needs. With a focus on data management and analytics, our suite of services empowers businesses to harness the full potential of their data assets.",
      icon: FaDatabase,
    },
    {
      id: 6,
      name: "Technical Support",
      description:
        "We are committed to providing complete technical support to meet all your IT needs. With our team of experienced professionals and a dedication to excellence, we offer a wide range of services designed to streamline your operations, enhance security, and drive innovation.",
      icon: MdOutlineSupportAgent,
    },
    {
      id: 7,
      name: "IOT Solution",
      description:
        "We specialize in delivering comprehensive IT solutions tailored to meet the demands of the Internet of Things (IoT) landscape. With a team of experienced professionals and cutting-edge technologies, we offer a wide range of services to empower businesses in harnessing the full potential of IoT. Our services include:",
      icon: FaGlobe,
    },

    {
      id: 8,
      name: "AWS",
      description:
        "We specialize in providing comprehensive IT solutions tailored to leverage the power of Amazon Web Services (AWS). With our expert team of AWS-certified professionals, we offer a wide range of services designed to optimize your business's infrastructure, enhance security, and drive growth.",
      icon: FaAws,
    },
    {
      id: 9,
      name: "Artificial Intelligence",
      description:
        "We specialize in providing comprehensive IT solutions tailored to harness the power of Artificial Intelligence (AI) for your business. With our team of seasoned professionals and cutting-edge technologies, we offer a wide range of services designed to optimize your operations, enhance decision-making, and drive sustainable growth.",
      icon: FaRobot,
    },
  ];
  const gotoServicesDetails = (name, id) => {
    navigate("/ServiceDetails", { state: { name: name, id: id } });
  };
  return (
    <div id={"Services"} className="bg-white md:py-6">
      <div className="mx-auto max-w-8xl px-6 lg:px-6 animate-fade-up animate-once animate-ease-in-out animate-normal animate-fill-both">
        {/* Title of the provide services */}
        <div className="mx-auto lg:text-center py-6">
          <h1 className="text-center poppins-regular text-lg py-10 text-primaryBlue">
            IT SUPPORT & MANAGED SERVICES IN USA
          </h1>
          <p className="text-4xl tracking-wider text-center poppins-medium text-primaryBlue">
            What can EnFix Solutions do for{" "}
            <span className=" text-primaryOrange poppins-semibold">
              my business?
            </span>
          </p>
        </div>
        {/* List of services */}
        <div className="mx-auto">
          <dl className="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-4 xl:max-w-none xl:grid-cols-3 xl:gap-y-0 pb-8">
            {features.map((feature) => (
              <div
                key={feature.id}
                className="p-8 hover:shadow-lg hover:animate-fade-up animate-once animate-ease-linear animate-normal animate-fill-forwards hover:border-1 hover:border-shadow border-1 border-white rounded-lg"
              >
                <div key={feature.name} className="relative pl-14">
                  <dt className="poppins-regular tracking-wider text-primaryBlue text-base poppins-medium">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center">
                      <feature.icon
                        className="h-8 w-8 text-primaryOrange"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 poppins-light text-sm	text-black">
                    {feature.description}
                  </dd>
                  <button
                    onClick={() =>
                      gotoServicesDetails(feature.name, feature.id)
                    }
                    class="mt-4 flex flex-row items-center"
                  >
                    <p className="poppins-light text-xs mr-2	text-black ">
                      {"Read More"}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="black"
                      class="w-3 h-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
export default Services;
